import React from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import UserCommunications from "./UserCommunications";
import LegislationData from "./LegislationData";
import NewsData from "./NewsData";
import FaqData from "./FaqData";
import OnboardingData from "./OnboardingData";
import { jwtDecode } from "jwt-decode";
import CalendarVaccencies from "./admin/CalendarVacancies";
import DayDetails from "./admin/DayDetails";
import { useTranslation } from "react-i18next";

const Communication = () => {
  // Decode the token to get EMPRESA and RHID
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  const rhid = decodedToken?.RHID || "";
  const isAdmin = () => rhid === 7002;
  const { t } = useTranslation();
  const title =
    rhid === 7002
      ? "Communication"
      : rhid === 7098
      ? "Communication"
      : "Communication";
  return (
    <>
      <CommonHeaderWithMenu title={t(title)} />
      {isAdmin() ? (
        <>
          <div className="bg-white rounded-lg shadow-md p-4 mb-16">
            <h1 className="p-3 text-center text=xl font-bold">
              {/* {t("Vacancies")} */}
            </h1>
            <UserCommunications />
            <LegislationData />
            <NewsData />
            <FaqData />
            <OnboardingData />
            {/* <CalendarVaccencies />
            <DayDetails /> */}
          </div>
        </>
      ) : (
        <>
          <div className="mb-16 bg-white rounded-lg shadow-md mt-6">
            <UserCommunications />
            <LegislationData />
            <NewsData />
            <FaqData />
            <OnboardingData />
            {/* <CalendarVaccencies />
            <DayDetails /> */}
          </div>
        </>
      )}

      <FooterNavigation />
    </>
  );
};

export default Communication;
