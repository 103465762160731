import React, { useState, useEffect } from "react";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import { toast } from "react-toastify";

const UpdateExpenseForm = () => {
  const { id } = useParams(); // Get the expense ID from the URL
  const [updatedExpense, setUpdatedExpense] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchExpenseDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${apiUrl}/api/expenses/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUpdatedExpense(data.expense);
          setLoading(false);
        } else {
          console.error("Failed to fetch expense details");
        }
      } catch (error) {
        console.error("Error fetching expense details:", error);
      }
    };

    fetchExpenseDetails();
  }, [id, apiUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedExpense((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${apiUrl}/api/expenses/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedExpense),
      });

      if (response.ok) {
        toast.success("Expense updated successfully!");
        navigate(`/expanses`); // Redirect to expense details page after successful update
      } else {
        console.error("Failed to update expense");
      }
    } catch (error) {
      console.error("Error updating expense:", error);
    }
  };

  if (loading) {
    return (
      <>
        {/* <!-- Loader --> */}
        <div className="flex items-center justify-center space-x-2 mt-16">
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-green-400 to-[#8AB53E]"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
        </div>
      </>
    );
  }

  return (
    <>
      {" "}
      <CommonHeaderWithMenu title="Expense Update" />
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-6">Update Expense</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-1 text-gray-600">Company:</label>
            <input
              type="text"
              name="EMPRESA"
              value={updatedExpense.EMPRESA || ""}
              onChange={handleInputChange}
              className="border border-gray-300 p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1 text-gray-600">Total Expense:</label>
            <input
              type="number"
              name="TOTAL_DESPESA"
              value={updatedExpense.TOTAL_DESPESA || ""}
              onChange={handleInputChange}
              className="border border-gray-300 p-3 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <button
            type="submit"
            className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 transition duration-200"
          >
            Save Changes
          </button>
        </form>
      </div>
      <FooterNavigation />
    </>
  );
};

export default UpdateExpenseForm;
