// import React, { useContext, useEffect, useState } from "react";
// import { Navigate, useNavigate } from "react-router-dom";
// import { AuthContext } from "./AuthContext";

// const ProtectedRoute = ({ children }) => {
//   const { isAuthenticated } = useContext(AuthContext);
//   const [isLoading, setIsLoading] = useState(true); // Manage loading state
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (isAuthenticated !== null) {
//       // Authentication status is determined, stop loading
//       setIsLoading(false);

//       // Redirect to the login page if session has expired
//       if (!isAuthenticated ) {
//         navigate("/");
//       }
//     }
//   }, [isAuthenticated, navigate]);

//   if (isLoading) {
//     // Show the loader while determining the auth status
//     return (
//       <div className="flex items-center justify-center h-screen bg-gray-100">
//         <div className="flex flex-col items-center space-y-4">
//           {/* Top Circular Loader */}
//           <div className="w-12 h-12 border-4 border-dotted border-green-400 border-t-transparent rounded-full animate-spin"></div>

//           {/* Text with Circular Dotted Loader for "O" */}
//           <p className="text-center text-gray-600 font-bold text-2xl">
//             Please wait s
//             <span className="inline-block relative w-4 h-4">
//               {/* Circular Dotted Loader */}
//               <div className="absolute inset-0 rounded-full border-2 border-dotted border-green-400 animate-spin"></div>
//             </span>
//             me time
//           </p>
//           <p className="text-center text-gray-600 font-bold text-2xl animate-pulse">
//             Thanks f
//             <span className="inline-block relative w-4 h-4">
//               {/* Circular Dotted Loader */}
//               <div className="absolute inset-0 rounded-full border-2 border-dotted border-green-400 animate-spin"></div>
//             </span>
//             r y
//             <span className="inline-block relative w-4 h-4">
//               {/* Circular Dotted Loader */}
//               <div className="absolute inset-0 rounded-full border-2 border-dotted border-green-400 animate-spin"></div>
//             </span>
//             ur patience!
//           </p>
//         </div>
//       </div>
//     );
//   }

//   // Render protected content if authenticated
//   return isAuthenticated ? children : null;
// };

// export default ProtectedRoute;

import React, { useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  console.log("isAuthenticated status:", isAuthenticated);

  const navigate = useNavigate();
  if (isAuthenticated === false) {
    return (
      <>
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="flex flex-col items-center space-y-4">
            <div className="w-12 h-12 border-4 border-dotted border-green-400 border-t-transparent rounded-full animate-spin"></div>

            <p className="text-center text-gray-600 font-bold text-2xl">
              Please wait s
              <span className="inline-block relative w-4 h-4">
                <div className="absolute inset-0 rounded-full border-2 border-dotted border-green-400 animate-spin"></div>
              </span>
              me time
            </p>
            <p className="text-center text-gray-600 font-bold text-2xl animate-pulse">
              Thanks f
              <span className="inline-block relative w-4 h-4">
                <div className="absolute inset-0 rounded-full border-2 border-dotted border-green-400 animate-spin"></div>
              </span>
              r patience!
            </p>
            <Link to="/" className="text-red-600 font-semibold text-center">
              <button>Logout</button>
            </Link>
          </div>
        </div>
      </>
    );
  }

  if (isAuthenticated !== true) {
    navigate("/");
    return null;
  }
  return children;
};

export default ProtectedRoute;
