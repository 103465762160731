import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import { toast } from "react-toastify";
import FooterNavigation from "../../pages/FooterNavigation";
import defaultUserImage from "../../assets/images/avater.png";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../ToggleSwitch";
import LanguageSelector from "../LanguageSelector";

const Settings = () => {
  const { t } = useTranslation();
  const [userImage, setUserImage] = useState(defaultUserImage);
  const [showLogout, setShowLogout] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  // Fetch user data including image on component mount
  useEffect(() => {
    const savedImageUrl = localStorage.getItem("userImage");
    if (savedImageUrl) {
      setUserImage(savedImageUrl);
    } else {
      fetchUserData(); // Only fetch from API if image not in localStorage
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/cadastre/user/data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const data = await response.json();
      if (data.userData.IMAGE_URL) {
        const imageUrl = data.userData.IMAGE_URL;
        setUserImage(imageUrl);
        localStorage.setItem("userImage", imageUrl); // Save to localStorage
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(`${apiUrl}/api/cadastre/user/upload-image`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const data = await response.json();
      if (data.imageUrl) {
        setUserImage(data.imageUrl);
        localStorage.setItem("userImage", data.imageUrl); // Update localStorage
        toast.success(data.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Failed to upload image");
    }
  };

  // const handleLogout = async () => {
  //   try {
  //     const response = await fetch(`${apiUrl}/api/auth/logout`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       credentials: "include",
  //     });

  //     if (response.ok) {
  //       localStorage.removeItem("token");
  //       navigate("/");
  //     } else {
  //       toast.error("Logout failed");
  //     }
  //   } catch (error) {
  //     toast.error("An error occurred during logout");
  //     console.error("An error occurred during logout", error);
  //   }
  // };
  const handleLogout = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/auth/logout`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (response.ok) {
        localStorage.removeItem("token");
        localStorage.removeItem("userImage"); // Clear any cached user image
        localStorage.removeItem("userInfo"); // Clear any cached user image

        // Clear all caches
        const cacheNames = await caches.keys();
        await Promise.all(
          cacheNames.map((cacheName) => caches.delete(cacheName))
        );

        // Unregister service worker to clear cached pages
        if (navigator.serviceWorker) {
          const registrations =
            await navigator.serviceWorker.getRegistrations();
          for (const registration of registrations) {
            registration.unregister();
          }
        }

        navigate("/"); // Redirect to login page
      } else {
        toast.error("Logout failed");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      toast.error("An error occurred during logout");
    }
  };

  return (
    <>
      <div className="bg-white text-black h-screen p-4 mb-32">
        <h1 className="text-2xl font-semibold mb-6">{t("Settings")}</h1>

        {/* User Image Section */}
        <div className="flex items-center mb-8">
          <img
            src={userImage}
            alt="User"
            className="w-16 h-16 rounded-full object-cover border-2 border-gray-300 mr-4"
          />
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("Change Profile Picture")}
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="mt-2"
            />
          </div>
        </div>

        {/* Main Settings Section */}
        <div className="space-y-6 mb-32">
          <div className="space-y-4 bg-[#f0f0f0] p-4 rounded-lg">
            <Link
              to="/settings/profile"
              className="flex justify-between items-center py-2"
            >
              <span>{t("Change Profile")}</span>
              <FiChevronRight />
            </Link>
            <Link
              to="/change-password"
              className="flex justify-between items-center py-2"
            >
              <span>{t("Change Password")}</span>
              <FiChevronRight />
            </Link>
            <div className="flex justify-between items-center py-2">
              <label htmlFor="active_fr">
                {t("Activate Facial Recognition")}
              </label>
              <ToggleSwitch />
            </div>
            <div className="flex justify-between items-center">
              <span>{t("Change Language")}</span>
              <LanguageSelector />
            </div>
          </div>

          {/* Logout Section */}
          <div className="space-y-4 bg-[#f0f0f0] p-4 rounded-lg">
            <button
              onClick={handleLogout}
              className="flex justify-between items-center py-2 w-full text-left text-red-500"
            >
              <span>{t("Logout")}</span>
              <FiChevronRight />
            </button>
          </div>
        </div>
      </div>
      <FooterNavigation />
    </>
  );
};

export default Settings;
