import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonHeaderWithMenu from "../../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../../FooterNavigation";

const EmployeeDetails = () => {
  const { rhid } = useParams(); // Get rhid from route parameters
  const [employeeDetails, setEmployeeDetails] = useState(null);
  console.log(employeeDetails);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  const fetchEmployeeDetails = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/api/cadastre/employee/details/${rhid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setEmployeeDetails(data.employee);
    } catch (error) {
      console.error("Error fetching employee details:", error);
    }
  };

  useEffect(() => {
    if (rhid) {
      fetchEmployeeDetails();
    }
  }, [rhid]);

  if (!employeeDetails) {
    return (
      <>
        {/* <!-- Loader --> */}
        <div className="flex items-center justify-center space-x-2 mt-16">
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-green-400 to-[#8AB53E]"></div>
          <div className="w-4 h-4 rounded-full animate-bounce bg-gradient-to-r from-[#8AB53E] to-green-400"></div>
        </div>
      </>
    );
  }

  return (
    <>
      <CommonHeaderWithMenu title="Employee Details" />
      <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-4">
        <h2 className="text-center text-2xl font-bold text-gray-800">
          Employee Details
        </h2>
        <div className="text-center">
          {employeeDetails.photo_url ? (
            <img
              src={employeeDetails.photo_url}
              alt="Employee"
              className="w-32 h-32 rounded-full mx-auto"
              onError={(e) => {
                e.target.src =
                  "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YXZhdGFyfGVufDB8fDB8fHww";
              }}
            />
          ) : (
            <p>No photo available</p>
          )}
        </div>
        <div className="text-center">
          <p className="text-xl font-semibold text-gray-900">
            {employeeDetails.NOME}
          </p>
          <p className="text-gray-500">
            <strong>Last Contract:</strong> {employeeDetails.last_contract}
          </p>
          <p className="text-gray-500">
            <strong>Cost Center:</strong> {employeeDetails.cost_center}
          </p>
        </div>
      </div>
      <FooterNavigation />
    </>
  );
};

export default EmployeeDetails;
