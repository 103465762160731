import React, { useEffect, useState } from "react";
import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import DelegationModal from "./DelegationModal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import RevokeDelegationModal from "./RevokeDelegationModal";

const Delegations = () => {
  const [delegations, setDelegations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRevokeModalVisible, setIsRevokeModalVisible] = useState(false);
  const [currentDelegation, setCurrentDelegation] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      fetchDelegations();
    } else {
      alert("No token found. Please log in.");
    }
  }, [token]);

  // Fetch all delegations
  const fetchDelegations = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/admin/delegations`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch delegations");
      }

      const data = await response.json();
      setDelegations(data.delegations);
    } catch (error) {
      console.error("Error fetching delegations:", error);
      alert("Failed to fetch delegations.");
    }
    setLoading(false);
  };

  // Register a new delegation
  // const registerDelegation = async (formData) => {
  //   try {
  //     const response = await fetch(`${apiUrl}/api/admin/delegations/register`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to register delegation");
  //     }

  //     const data = await response.json();
  //     toast.success(data.message);
  //     fetchDelegations(); // Refresh delegations after registration
  //   } catch (error) {
  //     console.error("Error registering delegation:", error);
  //     toast.error("Failed to register delegation.");
  //   }
  // };
  const registerDelegation = async (payload) => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/delegations/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to register delegation");
      }

      const data = await response.json();
      toast.success(data.message);
      fetchDelegations(); // Refresh the delegations list
    } catch (error) {
      console.error("Error registering delegation:", error);
      toast.error("Failed to register delegation.");
    }
  };

  // Revoke a delegation
  const revokeDelegation = async (formData) => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/delegations/revoke`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to revoke delegation");
      }

      const data = await response.json();
      toast.success(data.message);
      fetchDelegations(); // Refresh delegations after revocation
    } catch (error) {
      console.error("Error revoking delegation:", error);
      toast.error("Failed to revoke delegation.");
    }
  };
  const handleRevokeClick = (delegation) => {
    setCurrentDelegation(delegation); // Set current delegation for pre-filling modal
    setIsRevokeModalVisible(true); // Open revoke modal
  };

  return (
    <>
      <CommonHeaderWithMenu title={t("Delegations")} />
      <div className="p-6 bg-gray-100 min-h-screen mb-20">
        <h1 className="text-2xl font-bold mb-4">
          {t("Delegation Management")}
        </h1>
        <button
          onClick={() => setIsModalVisible(true)}
          className="px-4 py-2 bg-green-500 text-white rounded mb-4 hover:bg-green-600"
        >
          {t("Register Delegation")}
        </button>
        {/* // Pass registerDelegation to the modal */}
        <DelegationModal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSubmit={registerDelegation} // This is the function that makes the POST request
          title="Register New Delegation"
        />

        {/* <DelegationModal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSubmit={registerDelegation}
          title="Register New Delegation"
          fields={[
            { name: "empresa", label: "Empresa", placeholder: "Enter empresa" },
            {
              name: "rHIDDestino",
              label: "RHID Destino",
              placeholder: "Enter RHID Destino",
            },
            {
              name: "dtAdmissao",
              label: "DT Admissao",
              placeholder: "Enter DT Admissao",
              type: "datetime-local", // Use date-time picker
            },
            {
              name: "dtInicio",
              label: "DT Inicio",
              placeholder: "Enter DT Inicio",
              type: "datetime-local", // Use date-time picker
            },
            { name: "perfil", label: "Perfil", placeholder: "Enter Perfil" },
            {
              name: "obs",
              label: "Observations",
              placeholder: "Enter observations",
            },
          ]}
        /> */}
        <RevokeDelegationModal
          isVisible={isRevokeModalVisible}
          onClose={() => setIsRevokeModalVisible(false)}
          onSubmit={revokeDelegation}
          title="Revoke Delegation"
          fields={[
            { name: "empresa", label: "Empresa", placeholder: "Enter empresa" },
            {
              name: "rHIDDestino",
              label: "RHID Destino",
              placeholder: "Enter RHID Destino",
            },
            {
              name: "dtInicio",
              label: "DT Inicio",
              placeholder: "Enter DT Inicio",
              type: "datetime-local",
            },
            { name: "perfil", label: "Perfil", placeholder: "Enter Perfil" },
          ]}
          delegation={currentDelegation} // Pass current delegation for pre-filling
        />
        {loading ? (
          <div className="text-center">Loading delegations...</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {delegations.map((delegation, index) => (
              <div
                key={index}
                className="bg-white p-4 shadow-lg rounded-md border border-gray-200"
              >
                <p>
                  <strong>Empresa:</strong> {delegation.EMPRESA}
                </p>
                <p>
                  <strong>RHID:</strong> {delegation.RHID}
                </p>
                <p>
                  <strong>Perfil:</strong> {delegation.PERFIL}
                </p>
                <p>
                  <strong>DT Início:</strong> {delegation.DT_INICIO}
                </p>
                <button
                  onClick={() => handleRevokeClick(delegation)}
                  className="px-4 py-2 bg-red-500 text-white rounded mt-4 hover:bg-red-600"
                >
                  Revoke
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      <FooterNavigation />
    </>
  );
};

export default Delegations;
