import React, { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { notification as AntdNotification } from "antd";
import { jwtDecode } from "jwt-decode";

export const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0); // Track unread notifications

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) return;

    const decodedToken = jwtDecode(token);
    const rhid = decodedToken?.RHID;

    const socket = io(apiUrl, {
      query: { rhid }, // Pass RHID dynamically to the backend
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 30000,
      timeout: 24 * 60 * 60 * 1000, // 1 day timeout
    });

    socket.on("connect", () => {
      console.log("WebSocket connected:", socket.id);
    });

    socket.on("new-notification", (notification) => {
      console.log("Real-time notification received:", notification);

      // Show popup notification
      AntdNotification.open({
        message: notification.SUBJECT || "New Notification",
        description: notification.BODY || "You have a new notification.",
        icon: (
          <img
            src={notification.IMAGE_URL || "/default-notification.png"}
            alt="icon"
            style={{ width: 24, height: 24 }}
          />
        ),
        duration: 10, // Automatically close after 10 seconds
        style: { backgroundColor: "#f6ffed", border: "1px solid #b7eb8f" },
      });

      // Update notifications list
      setNotifications((prev) => [notification, ...prev]);
      setUnreadCount((count) => count + 1); // Increment unread count
    });

    socket.on("disconnect", () => {
      console.log("WebSocket disconnected:", socket.id);
    });

    return () => {
      socket.disconnect();
    };
  }, [apiUrl, token]);
  const removeNotification = (notificationId) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.ID_NOTIF !== notificationId)
    );
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, unreadCount, setUnreadCount, removeNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
