import React, { useEffect, useState } from "react";

import Calender from "../../assets/images/calendar-tick.png";
import Clock from "../../assets/images/clock.png";
import AppointmentSection from "../../component/employee/AppionmentSection";
import AttendanceIndexCard from "../../component/employee/AttendanceIndexCard";

import CommonHeaderWithMenu from "../../component/shared/CommonHeaderWithMenu";
import FooterNavigation from "../FooterNavigation";
import { jwtDecode } from "jwt-decode";
import TimeManagementSection from "./TimeManagementSection";
import { useTranslation } from "react-i18next";

const Employee = () => {
  const { t } = useTranslation();
  const [fairDays, setFairDays] = useState(0);
  const [adaptability, setAdaptability] = useState({
    adaptabilityHours: 0,
    adaptabilityMinutes: 0,
  });
  const [attendanceIndex, setAttendanceIndex] = useState(0);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Decode the token to get EMPRESA and RHID
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  const empresa = decodedToken?.EMPRESA || "";
  const rhid = decodedToken?.RHID || "";
  // console.log(rhid);
  // Define the title based on rhid
  const title =
    rhid === 7002
      ? `${t("employeeAdmin")}`
      : rhid === 7098
        ? `${t("employeeEmployee")}`
        : `${t("defaultTitleEmployee")}`;

  useEffect(() => {
    if (!empresa || !rhid) {
      console.error("EMPRESA or RHID is missing from the token");
      return;
    }

    const fetchEmployeeData = async () => {
      try {
        // Fetch fairs data
        const fairsResponse = await fetch(`${apiUrl}/api/attendance/fairs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const fairsData = await fairsResponse.json();
        setFairDays(fairsData.fairDays || 0);

        // Fetch adaptability hours
        const adaptabilityResponse = await fetch(
          `${apiUrl}/api/attendance/adaptability`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const adaptabilityData = await adaptabilityResponse.json();
        setAdaptability({
          adaptabilityHours: adaptabilityData.adaptabilityHours || 0,
          adaptabilityMinutes: adaptabilityData.adaptabilityMinutes || 0,
        });

        // Fetch attendance index
        const attendanceResponse = await fetch(
          `${apiUrl}/api/attendance/index`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const attendanceData = await attendanceResponse.json();
        setAttendanceIndex(attendanceData.attendanceIndex || 0);
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, [apiUrl, empresa, rhid]);
  return (
    <>
      <CommonHeaderWithMenu title={title} />
      <div className="p-4 bg-gray-50 min-h-screen ">
        {/* Top Banner */}
        {/* <div className="bg-blue-100 p-4 rounded-md shadow-md text-center mb-6">
          <div className="text-white bg-blue-500 rounded-full w-20 h-8 mx-auto text-center flex items-center justify-center">
            428 x 50
          </div>
        </div> */}
        {/* <HeaderWithSearch /> */}
        {/* Appointment Section */}
        <AppointmentSection />
        {/* <div className="bg-green-100 p-6 rounded-lg shadow-lg mb-6 relative">
          <div className="text-lg font-semibold text-gray-700">appointment</div>
          <p className="text-gray-500">
            Current Time 14:03 Your last appointment was EXIT 321 day(s) ago at
            18:00 hours.
          </p>
          <div className="absolute top-4 right-4 bg-green-200 rounded-full w-24 h-24 flex items-center justify-center">
            <div className="text-green-700">14:03</div>
          </div>
          <div className="mt-4 flex justify-between">
            <button className="bg-green-500 text-white px-4 py-2 rounded-md">
              Entrance
            </button>
            <button className="bg-red-500 text-white px-4 py-2 rounded-md">
              Exit
            </button>
          </div>
        </div> */}

        {/* Metrics Section */}
        <div className="grid grid-cols-2 gap-4 mb-6 mt-6">
          <div>
            <div className="bg-[#CFDEE7] p-4 rounded-xl border border-[#BED4E1]">
              <div className="flex items-center">
                <div className="bg-[#AFCCDD] h-[48px] w-[55px] rounded-full flex items-center justify-center">
                  <img src={Calender} alt="" className="h-6 w-6" />
                </div>
                <div>
                  <div className="text-xl font-bold text-gray-700 ml-3">
                    +{fairDays}
                  </div>
                  <p className="text-[#373737] ml-3 font-medium">{t('days')}</p>
                </div>
              </div>
              <p className="text-[#373737] mt-4 font-semibold">{t('Fairs')}</p>
            </div>
          </div>
          <div>
            <div className="bg-[#EFD9CE] p-4 rounded-xl border border-[#E4CBBE]">
              <div className="flex items-center">
                <div className="bg-[#E9CCBD] h-[48px] w-[55px] rounded-full flex items-center justify-center">
                  <img src={Clock} alt="" className="h-6 w-6" />
                </div>
                <div>
                  <div className="text-xl font-bold text-gray-700 ml-3">
                    {adaptability.adaptabilityHours}:
                    {adaptability.adaptabilityMinutes}
                  </div>
                  <p className="text-[#373737] ml-3 font-medium">{t('Hours')}</p>
                </div>
              </div>
              <p className="text-[#373737] mt-4 font-semibold">{t('Adaptability')}</p>
            </div>
          </div>
        </div>

        <AttendanceIndexCard attendanceIndex={attendanceIndex} />

        {/* Time Management Section */}
        <div className="mb-16">
          <div className="text-[18px] font-semibold text-[#373737] mb-2">
            {t('Time Management')}
          </div>
          {/* <div className="bg-[#F8F8F8] border border-[#D9D9D9] p-4 rounded-xl  mb-4">
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Argument:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">
                09:00 to 13:00 | 09:00 to 13:00
              </span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Tomorrow:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">
                09:00 to 13:00 | 09:00 to 13:00
              </span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Saturday:</span>{" "}
              <span></span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Domingo:</span>{" "}
              <span></span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Monday:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">
                09:00 to 13:00 | 09:00 to 13:00
              </span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Tuesday:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">
                09:00 to 13:00 | 09:00 to 13:00
              </span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Wednesday:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">
                09:00 to 13:00 | 09:00 to 13:00
              </span>
            </p>
          </div> */}
          {/* <div className="bg-[#F8F8F8] border border-[#D9D9D9] p-4 rounded-xl  mb-4">
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Argument:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">08:00</span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Tomorrow:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">08:00</span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Saturday:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">08:00</span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Domingo:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">08:00</span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Monday:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">08:00</span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Tuesday:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">08:00</span>
            </p>
            <p className="flex justify-between ">
              <span className="text-[#373737] font-semibold">Wednesday:</span>{" "}
              <span className="text-[14px] mt-1 text-[#7D7D7D]">08:00</span>
            </p>
          </div> */}
          <TimeManagementSection token={token} />
        </div>

        {/* Footer Navigation */}
        <FooterNavigation />
      </div>
    </>
  );
};

export default Employee;
